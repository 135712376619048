/* You can add global styles to this file, and also import other style files */
/* material prebuilt themese are indigo-pink, deeppurple-amber, purple-green and pink-bluegrey


@import "~@angular/material/prebuilt-themes/pink-bluegrey.css";
 */
@import "https://js.arcgis.com/3.23/esri/css/esri.css";
@import "https://js.arcgis.com/3.23/dijit/themes/claro/claro.css";



#mainMap_gc path{
  fill-opacity: 0.25 !important;
}​


.mat-column-date, .cdk-column-date {
  flex: 0 0 85px !important;
}
.mat-column-ProjectNumber, .cdk-column-ProjectNumber {
  flex: 0 0 100px !important;
}

html, body, app-root {
  padding:0;
  margin: 0;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
}

app-map-view, app-reports {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 100vw;
}

app-reports {
  margin: 15px;
}

app-main-map {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 50vw
}

app-edit-pane, app-list-view {
  flex: 1;
  display: flex;
  flex-direction: column;
}

app-project-form, app-related-table-form, app-list-view, app-review-form,
app-assigned-task, app-fieldwork, app-cultural-resources, app-stipulations, app-comments, app-comm, app-attachment-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  padding: 15px;
}

app-project-form .mat-card, app-related-table-form .mat-card, app-list-view .mat-card, app-assigned-task
.mat-card, app-fieldwork .mat-card, app-cultural-resources .mat-card, app-stipulations .mat-card, app-review-form .mat-card, app-reports .mat-card,app-attachment-form .mat-card{
  overflow-y: auto;
}

/*div.dijitPopup {*/
  /*position: absolute;*/
/*}*/
.BasemapToggle {
  position: absolute;
  top: 155px;
  z-index: 1;
}
mat-form-field {
  width: 100%
}

mat-row.active {
  /*background-color: whitesmoke;*/
  cursor: pointer;
}

.mat-header-row {
  position: sticky;
  top: 0;
  background: #FAFAFA;
  z-index: 999;
}

.mat-tab-link {
  padding: 0 6px !important;
  min-width: 75px !important;
}

.mat-tab-label-container .mat-tab-label {
  opacity: 1;
  padding: 0;
}


.mat-tab-group.mat-primary .mat-ink-bar {
  display: none;
}

.mat-toolbar {
  /*min-height: 90px !important;*/
   /*background-color: #e2d5ce !important;*/
  background: #e2d5ce !important; /* prod and CBUTI*/

}

span:focus {
  outline: none;
  border: 0;
}

*:focus {
  outline: none !important;
  border: 0 !important;
}


.fill-remaining-space {
  flex: 1 1 auto;
}
.cris_item{
  cursor: pointer !important;
  padding-left: 10px !important;
}



